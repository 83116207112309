.ImageSection{
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;
    .img{
        width: 100%;
        height: 50vh;
        img{ 
            width: 100%;
            object-fit: cover;
            height: 100%;
        }
    }

    .about-info{
        margin-left: 2rem;
        h4{
            font-size: 2rem;
        }
        .about-text{
            padding: 1rem 0;
        }
    }

     
    @media screen and (max-width: 1200px){
        flex-direction: column;
        .about-info{
            margin-left: 0;
            margin-top: 1rem;
        }
    } 
}

.btn{
    padding: 1rem 2rem;
    background-color: #037FFF;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 2px;
    margin-top: 1rem;
    position: relative;
    &::after{
        position: absolute;
        content: "";
        width: 0;
        height: .3rem;
        left: 0;
        bottom: 0;
        background-color: #a4acc4;
        transition: all 0.4s ease-in-out;
    }
    &:hover::after{
        width: 100%;
    }
}

.skillsContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    row-gap: 3rem;
    margin: 4rem 0;
    @media screen and (max-width: 1200px){
        grid-template-columns: repeat(1, 1fr);
    }
}