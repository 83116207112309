.Title{
    text-transform: uppercase;
    font-size: 2rem;
    color: rgb(0, 0, 0);
    position: relative;
    padding-bottom: 1rem;
    letter-spacing: 3px;
    &::before{
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        background-color: #0381ff44;
        width: 6rem;
        height: .4rem;
        border-radius: 50px;
    }
    &::after{
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        background-color: #037FFF;
        width: 3rem;
        height: .4rem;
        border-radius: 50px;
    }
    span{
        position: absolute;
        top: 15%;
        left: 0;
        font-size: 5rem;
        opacity: 0.06
        ;
    }
}