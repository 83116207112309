.btn-port-p{
    padding: .6rem 2rem;
    background-color: #0381ff7e;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;
    color: white;
    margin-bottom: 1rem;
    transition: all .5s ease-in-out;
    &:not(:last-child){
        margin-right: 1rem;
    }
    &:hover{
        background-color: #037FFF;
    }
    &:active{
        background-color:#037FFF;
    }
    &:focus{
        background-color:#037FFF;
    }
}

.buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 5rem;
    flex-wrap: wrap;
    @media screen and (max-width: 1270px){
        margin: 2rem 5rem;
    }
    @media screen and (max-width: 1100px){
        margin: 2rem 2rem;
    }
}


.portfolios{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    row-gap: 1.5rem;
    column-gap: 2rem;
    @media screen and (max-width:1600px){
        grid-template-columns: repeat(2,1fr);
    }
    @media screen and (max-width:900px){
        grid-template-columns: repeat(1,1fr);
    }
    .portfolio{
        .image-data{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                top: 12px;
                left: 15px;
                height: calc(100% - 30px);
                width: calc(100% - 30px);
                background-color:rgba(255, 255, 255, 0.719);
                transform-origin: center;
                transform: scale(0);
                transition: all 0.4s ease-in-out;
            }
            &:hover::before{
                transform: scale(1);
            }
            img{
                width: 100%;
                height: 30vh;
                object-fit: cover;
            }
            .hover-items{
                list-style: none;
                position:absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%,-50%);
                padding: 1rem 2rem;
                visibility: hidden;
                li{
                    position: relative;
                    a{
                        padding: 1rem;
                        text-decoration: none;
                        font-family: inherit;
                        border-radius: 10px;
                        background-color: #037FFF;
                        color: white;
                        &:not(:last-child){
                            margin-right: 2rem;
                        }
                    }
                }
            }

            &:hover{
                .hover-items{
                    transition: all 0.5s ease-in-out .2s;
                    visibility: visible;
                    top: 50%;
                }
            }
        }
        h5{
            font-size: 1.6rem;
            font-weight: 400;
            color: white;
            transition: all .3s ease-in-out;
            &:hover{
                color: #037FFF;
                cursor: pointer;
            }
        }
    }
}