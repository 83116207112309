@import './styles/layout';
@import './styles/navbar';
@import './styles/typography';
@import './styles/title';
@import './styles/about';
@import './styles/skills';
@import './styles/blogs';
@import './styles/portfolios';

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body::-webkit-scrollbar{
    width: 8px;
}
body::-webkit-scrollbar-thumb{
    background-color: #0381ff79;
    border-radius: 50px;
}
body::-webkit-scrollbar-track{
    background-color: #a4acc4;
}

body{
    color: #000000;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.2rem;
}

span{
    color: #037FFF ;
}

@media screen and (max-width: 400px){
    html{
        font-size: 80%;
    } 
}

@media screen and (max-width: 700px){
    html{
        font-size: 90%;
    } 
}

@media screen and (max-width: 480px){
    h2{
        font-size: 60%;
    } 
    h3{
        font-size: 60%;
    }
}