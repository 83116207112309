.HomePage{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    width: 80%;
}

.profile{
    // width: 100%;
    text-align: center;
    margin-top: 1rem;
    // border-bottom: 1px solid #000000;
    img{
        width: 20%;
        // border-radius: 100%;
        // border: 5px solid #000000;   
        margin-bottom: 0rem; 
    }
}

.hero-text{
    // border-top: 1px solid #000000;
    margin-bottom: 1rem; 
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 3.6rem;
    span{
        color: #037FFF;
    }
}

.h-sub-text{
    text-align: center;
}

.icons{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
.icon-holder{
    margin-right: 1rem;
    .icon{
        font-size: 2.8rem;
        color: #000000;
        transition: all .4 ease-in-out;
    }
    .yt:hover{
        color: #037FFF;
    }
    .fb:hover{
        color:#037FFF;
    }
    .gh:hover{
        color: #037FFF;
    }

}
}