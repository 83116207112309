.b-title{
    margin-bottom: 4rem;
}

.BlogsPage{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem;
    @media screen and (max-width: 1000px){
        grid-template-columns: repeat(1,1fr);
    }
    .blog{
        background-color: #161925;
        border-radius: 5%;
        width: 100%;
        padding: 1rem;
        overflow: hidden;
        .blog-content{
            overflow: hidden;
            border-radius: 5%;
            padding-bottom: 1.5rem;
            img{
                width: 100%;
                border-radius: 5%;
                transition: all .2s ease-in-out;
                &:hover{
                    transform: scale(1.1);
                }
                padding-bottom: 1.5rem;
            }
            .blog-link{
                color: inherit;
                font-family: inherit;
                text-decoration: none;
                font-size: 1.5rem;
                padding-bottom: 3rem;
                transition: all .2s ease-in-out;
                &:hover{
                    color: #037FFF;
                }

            }

        }
    }
}